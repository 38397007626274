<template>
  <b-overlay
    :opacity="0.71"
    :show="acceptLoading"
  >
    <b-card
      v-if="data"
      no-body
      class="mt-1 card-company-table"
    >
      <b-card-header class="pb-1">
        <b-card-title>{{ cardTitle }} </b-card-title>
        <feather-icon
          icon="MoreVerticalIcon"
          size="18"
          class="cursor-pointer"
        />
      </b-card-header>

      <b-card-body>
        <b-card-text>
          <span v-if="!checkCollapse">
            <b-badge variant="danger">
              не укладывается в kpi
            </b-badge>
            <br> <br>
          </span>
          <span>{{ cardText }}</span>
          <br>
          <b-link
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal="'modal-xs' + recommendationType"
            style="text-decoration: dotted underline;
              text-underline-offset: 3px;"
            variant="outline-primary"
          >
            Как считаем расхождение?
          </b-link>
          <b-modal
            :id="'modal-xs' + recommendationType"
            cancel-variant="secondary"
            ok-only
            ok-title="Окей"
            centered
            size="xs"
            title="Расчет расхождения"
          >
            <b-card-text>
              <b>Формула расчета расхождения:</b> <br>
              <p>KPI (целевой на проекте) - Данные по ключу (например, CPO (стоимость заказа)) / KPI (целевой на проекте) </p>
            </b-card-text>
          </b-modal>
        </b-card-text>

        <app-collapse
          class="mt-1"
          accordion
        >
          <app-collapse-item
            title="Найдено ключей"
            :is-visible="checkCollapse"
            :count="count"
          >
            <span>
              <b>
                Выбрано <b-badge>{{ countChecked }}</b-badge> ключей
              </b>
            </span>
            <br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal="'adjustment-modal' + recommendationType"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75 mt-1"
            >
              Применить корректировки
            </b-button>
            <b-modal
              :id="'adjustment-modal' + recommendationType"
              ok-title="Применить"
              cancel-title="Отменить выборку"
              centered
              title="Корректировка ставок"
              @cancel="cancelKeys"
              @ok="correctKeys"
            >
              Применить корректировки к {{ countChecked }} ключам?
            </b-modal>

            <b-table
              ref="table"
              :data="data"
              detailed
              :per-page="perPage.value"
              hoverable
              :paginated="true"
              detail-key="id"
              height="600px"
              :row-class="(row, index) => 'is-info'"
              :sticky-header="true"
              custom-detail-row
              :show-detail-icon="showDetailIcon"
              class="mt-2"
            >
              <!-- campaign -->
              <b-table-column
                v-slot="props"
                field="campaign"
                :label="columnsVisible['campaign'].title"
                width="300"
              >
                <template v-if="showDetailIcon">
                  <span>
                    {{ props.row.campaign }}
                  </span>
                </template>
                <template v-else>
                  <a @click="toggle(props.row)">
                    {{ props.row.campaign }}
                  </a>
                </template>
              </b-table-column>

              <!-- Cr -->
              <b-table-column
                field="kpiFact_cr"
                :visible="columnsVisible['kpiFact_cr'].display"
                :label="columnsVisible['kpiFact_cr'].title"
                centered
              >
                <template v-slot:header="{ column }">
                  <span
                    v-b-tooltip.hover.v-info
                    title="Cr"
                    variant="outline-info"
                  >
                    {{ column.label }}
                  </span>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpiFact_cr) }}
                </template>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="kpi_cr"
                :visible="columnsVisible['kpi_cr'].display"
                :label="columnsVisible['kpi_cr'].title"
                centered
              >
                {{ columnValueFormat(props.row.kpi_cr) }}
              </b-table-column>

              <!-- Cost (CRM) -->
              <b-table-column
                field="kpiFact_cost"
                :visible="columnsVisible['kpiFact_cost'].display"
                :label="columnsVisible['kpiFact_cost'].title"
                centered
              >
                <template v-slot:header="{ column }">
                  <span
                    v-b-tooltip.hover.v-info
                    title="целевой CPO"
                    variant="outline-info"
                  >
                    {{ column.label }}
                  </span>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpiFact_cost) }}
                </template>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="kpi_cost"
                :visible="columnsVisible['kpi_cost'].display"
                :label="columnsVisible['kpi_cost'].title"
                centered
              >
                {{ columnValueFormat(props.row.kpi_cost) }}
              </b-table-column>

              <!-- Ctr -->
              <b-table-column
                field="kpiFact_ctr"
                :visible="columnsVisible['kpiFact_ctr'].display"
                :label="columnsVisible['kpiFact_ctr'].title"
                centered
              >
                <template v-slot:header="{ column }">
                  <span
                    v-b-tooltip.hover.v-info
                    title="Ctr"
                    variant="outline-info"
                  >
                    {{ column.label }}
                  </span>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpiFact_ctr) }}
                </template>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="kpi_ctr"
                :visible="columnsVisible['kpi_ctr'].display"
                :label="columnsVisible['kpi_ctr'].title"
                centered
              >
                {{ columnValueFormat(props.row.kpi_ctr) }}
              </b-table-column>

              <!-- Roi -->
              <b-table-column
                field="kpiFact_roi"
                :visible="columnsVisible['kpiFact_roi'].display"
                :label="columnsVisible['kpiFact_roi'].title"
                centered
              >
                <template v-slot:header="{ column }">
                  <span
                    v-b-tooltip.hover.v-info
                    title="Roi"
                    variant="outline-info"
                  >
                    {{ column.label }}
                  </span>
                </template>
                <template v-slot="props">
                  {{ columnValueFormat(props.row.kpiFact_roi) }}
                </template>
              </b-table-column>
              <b-table-column
                v-slot="props"
                field="kpi_roi"
                :visible="columnsVisible['kpi_roi'].display"
                :label="columnsVisible['kpi_roi'].title"
                centered
              >
                {{ columnValueFormat(props.row.kpi_roi) }}
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="discrepancy"
                :visible="columnsVisible['discrepancy'].display"
                :label="columnsVisible['discrepancy'].title"
                sortable
                centered
              >
                <b-badge :variant="changeColor">
                  {{ columnValueFormat(props.row.discrepancy) }}
                </b-badge>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="action"
                :visible="columnsVisible['action'].display"
                :label="columnsVisible['action'].title"
                centered
              >
                <b-form-checkbox
                  v-model="props.row.action"
                  style="width: 15px;margin: auto;"
                  class="custom-control-primary"
                  @change="check($event, props.row.id)"
                />
              </b-table-column>

              <template
                slot="detail"
                slot-scope="props"
              >
                <tr
                  v-for="item in props.row.items"
                  :key="item.id"
                >
                  <td v-if="showDetailIcon" />
                  <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;{{ item.criterion }}
                    <b-button
                      v-if="isTrafficMore(item.avgTrafficVolume)"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      v-b-tooltip.hover
                      variant="flat-success"
                      class="btn-icon rounded-circle sm"
                      title="Средний объем трафика < 90%"
                    >
                      <feather-icon
                        variant="success"
                        icon="TrendingUpIcon"
                      />
                    </b-button>
                  </td>
                  <td
                    v-show="columnsVisible['kpiFact_cr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpiFact_cr) }}
                  </td>
                  <td
                    v-show="columnsVisible['kpi_cr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi_cr) }}
                  </td>

                  <td
                    v-show="columnsVisible['kpiFact_cost'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpiFact_cost) }}
                  </td>
                  <td
                    v-show="columnsVisible['kpi_cost'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi_cost) }}
                  </td>

                  <td
                    v-show="columnsVisible['kpiFact_ctr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpiFact_ctr) }}
                  </td>
                  <td
                    v-show="columnsVisible['kpi_ctr'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi_ctr) }}
                  </td>

                  <!-- roi -->
                  <td
                    v-show="columnsVisible['kpiFact_roi'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpiFact_roi) }}
                  </td>
                  <td
                    v-show="columnsVisible['kpi_roi'].display"
                    class="has-text-centered"
                  >
                    {{ columnValueFormat(item.kpi_roi) }}
                  </td>

                  <td
                    v-show="columnsVisible['discrepancy'].display"
                    class="has-text-centered"
                  >
                    <b-badge :variant="changeColor">
                      {{ columnValueFormat(item.discrepancy) }}
                    </b-badge>
                  </td>
                  <td
                    v-show="columnsVisible['action'].display"
                    class="has-text-centered"
                  >
                    <b-form-checkbox
                      v-model="item.action"
                      style="width: 15px;margin: auto;"
                      class="custom-control-primary"
                    />
                  </td>
                </tr>
              </template>
            </b-table>
          </app-collapse-item>
        </app-collapse>
        <b-col cols="8">
          <!-- button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1"
            block
            @click="setAllKeys"
          >
            Применить корректировки ко всем ключам
          </b-button>
        </b-col>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BBadge, BLink, BFormCheckbox, BCardBody, BCardTitle, BCardHeader, BButton, BCol, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BCol,
    BFormCheckbox,
    BCardHeader,
    BCardTitle,
    VBTooltip,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    visibleProps: {
      type: Array,
      default: () => [],
    },
    recommendationType: {
      type: String,
      default: () => null,
    },
    count: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      acceptLoading: false,
      showDetailIcon: true,
      columnsVisible: {
        // cr crm
        kpiFact_cr: { title: 'KPI (целевой Cr)', display: false },
        kpi_cr: { title: 'KPI (Cr)', display: false },
        // cost crm
        kpiFact_cost: { title: 'KPI (целевой CPO)', display: false },
        kpi_cost: { title: 'KPI (CPO)', display: false },
        // ctr
        kpiFact_ctr: { title: 'KPI (целевой Ctr)', display: false },
        kpi_ctr: { title: 'KPI (Ctr)', display: false },
        // roi crm
        kpiFact_roi: { title: 'KPI (целевой Roi)', display: false },
        kpi_roi: { title: 'KPI (Roi)', display: false },

        campaign: { title: 'Кампания', display: true, type: 'campaign' },
        discrepancy: { title: 'Расхождение (%)', display: true },
        action: { title: 'Действия', display: true },
      },
      perPage: {
        value: 30,
        text: '30 на странице',
      },
    }
  },
  computed: {
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    countChecked() {
      let count = 0
      this.data.forEach(item => {
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          if (subItem.action) {
            count += 1
          }
        })
      })
      return count
    },
    changeColor() {
      let text

      switch (this.recommendationType) {
        case 'lessSeventy':
          text = 'danger'
          break
        case 'moreSeventy':
          text = 'danger'
          break
        case 'normal':
          text = 'success'
          break
        default:
          break
      }

      return text
    },
    cardTitle() {
      let text

      switch (this.recommendationType) {
        case 'lessSeventy':
          text = 'Больше чем на 70%'
          break
        case 'moreSeventy':
          text = 'Меньше чем на 70%'
          break
        case 'normal':
          text = 'В пределах KPI'
          break
        default:
          break
      }

      return text
    },
    checkCollapse() {
      let result

      switch (this.recommendationType) {
        case 'lessSeventy':
          result = false
          break
        case 'moreSeventy':
          result = false
          break
        case 'normal':
          result = true
          break
        default:
          break
      }

      return result
    },
    cardText() {
      let text

      switch (this.recommendationType) {
        case 'lessSeventy':
          text = 'KPI по ключам отличается больше чем на 70% от ключевого показателя'
          break
        case 'moreSeventy':
          text = 'KPI по ключам отличается меньше чем на 70% от ключевого показателя'
          break
        case 'normal':
          text = 'Ключи укладываются в KPI'
          break
        default:
          break
      }

      return text
    },
    getOptions() {
      let options

      switch (this.recommendationType) {
        case 'lessSeventy':
          options = 2
          break
        case 'moreSeventy':
          options = 3
          break
        case 'normal':
          options = 1
          break
        default:
          break
      }

      return options
    },
  },
  watch: {
    visibleProps(oldState, newState) {
      this.visibleProps.forEach(item => {
        this.columnsVisible[item].display = true
      })
    },
  },
  mounted() {
    this.visibleProps.forEach(item => {
      this.columnsVisible[item].display = true
    })
  },
  methods: {
    isTrafficMore(val) {
      return val < 90 && this.recommendationType === 'normal'
    },
    cancelKeys() {
      this.data.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action = false
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          subItem.action = false
        })
      })
    },
    setAllKeys() {
      this.data.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action = true
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          subItem.action = true
        })
      })
      this.$bvModal.show(`adjustment-modal${this.recommendationType}`)
    },
    sendAdjustmentKeys() {
      console.log('sendAdjustmentKeys')
      const keys = []
      this.acceptLoading = true
      this.data.forEach(item => {
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          if (subItem.action) {
            const parseKey = {
              campaign: item.campaign,
              keyword: subItem.criterion,
            }
            keys.push(parseKey)
          }
        })
      })

      this.$http.post('yandex-direct/criterion-ids', keys)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // TO-DO: NEED refactoring
          const responseData = response.data

          const obj = {
            keywordIds: responseData,
            projectId: this.activeProjectId,
            options: this.getOptions,
          }

          this.$http.post('recommendation/execute', obj)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              // TO-DO: NEED refactoring
              this.acceptLoading = true
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Корректировка',
                  icon: 'PlayIcon',
                  variant: 'success',
                  text: 'Корректировка применена',
                },
              }, {
                position: 'top-center',
              })
            })
        })
    },
    check(e, id) {
      this.$nextTick(() => {
        const item = this.data.find(c => c.id === id)
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          subItem.action = e
        })
      })
    },
    columnValueFormat(val) {
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return (+val.toFixed(2)).toLocaleString()
      }

      return (+val).toLocaleString()
    },
    correctKeys() {
      this.sendAdjustmentKeys()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include.scss';
@import '~@core/scss/base/components/_variables-dark.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>

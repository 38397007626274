<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-tabs v-if="settingResult != null">
          <b-tab
            title="Автоматические рекомендации"
            active
          >
            <b-overlay
              :opacity="0.71"
              :show="isRecommendationShow"
            > <b-col cols="12">
                <div
                  v-if="checkResult == null && isRecommendationShow"
                  class="misc-inner p-2 p-sm-10 mt-3"
                >
                  <div class="w-100 text-center">
                    <h3 class="mb-1">
                      Пытаемся получить автоматический отчет рекомендаций...
                    </h3>
                  </div>
                </div>

              </b-col>
              <div
                v-if="checkResult != null && checkResult.type === 0"
                class="misc-inner p-2 p-sm-10 mt-3"
              >
                <div class="w-100 text-center">
                  <h3 class="mb-1">
                    {{ checkResult.description }}
                  </h3>
                  <p class="mb-2">
                    😖 Зайдите позже для просмотра или создайте рекомендацию за определенный период.
                  </p>
                </div>
              </div>
              <div
                v-if="checkResult != null && checkResult.type !== 0"
                class="mt-1"
              >
                <b-row>
                  <b-col lg="6">
                    <keys-recommendation-dashboard
                      :visible-props="visibleProps"
                      :data="dataInNotSeventyRange"
                      :recommendation-type="'moreSeventy'"
                      :count="calculateCount(dataInNotSeventyRange)"
                    />
                  </b-col>
                  <b-col lg="6">
                    <keys-recommendation-dashboard
                      :visible-props="visibleProps"
                      :data="dataInNotLessSeventyRange"
                      :recommendation-type="'lessSeventy'"
                      :count="calculateCount(dataInNotLessSeventyRange)"
                    />
                  </b-col>
                  <b-col lg="6">
                    <keys-recommendation-dashboard
                      :visible-props="visibleProps"
                      :data="dataInRange"
                      :recommendation-type="'normal'"
                      :count="calculateCount(dataInRange)"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-overlay>
          </b-tab>
          <b-tab title="Отчет эффективности">
            <div
              v-if="checkEfficiencyResult != null && checkEfficiencyResult.type === 0"
              class="misc-inner p-2 p-sm-10 mt-3"
            >
              <div class="w-100 text-center">
                <h3 class="mb-1">
                  Отчет эффективности применения корректировок не готов
                </h3>
                <p class="mb-2">
                  😖 {{ checkEfficiencyResult.description }}
                </p>
              </div>
            </div>
            <div v-if="checkEfficiencyResult != null && checkEfficiencyResult.type === 1">
              <b-card no-body>
                <b-overlay
                  :show="checkLoading"
                  rounded="sm"
                >
                  <b-card-header>
                    <b-card-title>
                      Отчет эффективности
                    </b-card-title>
                  </b-card-header>
                  <b-card-body>

                    <b-row>
                      <b-col
                        cols="12"
                        md="4"
                        lg="3"
                        class="mb-1"
                      >
                        <span>
                          <b>
                            Выбрано <b-badge>{{ countChecked }}</b-badge> ключей
                          </b>
                        </span>
                        <br>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          v-b-modal="'adjustment-modal-eff'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75 mt-1"
                        >
                          Отменить корректировки
                        </b-button>
                        <b-modal
                          :id="'adjustment-modal-eff'"
                          ok-title="Применить"
                          cancel-title="Отменить выборку"
                          centered
                          title="Применить"
                          @cancel="cancelKeys"
                          @ok="correctKeys"
                        >
                          Отменить корректировки {{ countChecked }} ключам?
                        </b-modal>

                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        lg="3"
                        class="mb-1"
                      >
                        <div
                          class="align-items-center justify-content-end"
                          style="margin-left: -14px; margin-top: 2px;"
                        >
                          <label>Фильтр по таблице</label>
                          <v-select
                            v-model="filterData"
                            :searchable="false"
                            :options="optionsFilter"
                            place-holder="Выберите"
                            label="text"
                          >
                            <template v-slot:no-options>
                              Ничего не найдено
                            </template>
                          </v-select>
                        </div>
                      </b-col>
                    </b-row>

                    <b-table
                      ref="table"
                      :data="effectData"
                      detailed
                      :per-page="perEffectPage.value"
                      hoverable
                      :paginated="true"
                      detail-key="id"
                      height="600px"
                      :row-class="(row, index) => 'is-info'"
                      :sticky-header="true"
                      custom-detail-row
                      :show-detail-icon="showDetailIcon"
                      class="mt-2"
                    >
                      <!-- campaign -->
                      <b-table-column
                        v-slot="props"
                        field="campaign"
                        :label="columnsVisible['campaign'].title"
                        width="300"
                      >
                        <template v-if="showDetailIcon">
                          <span>
                            {{ props.row.campaign }}
                          </span>
                        </template>
                        <template v-else>
                          <a @click="toggle(props.row)">
                            {{ props.row.campaign }}
                          </a>
                        </template>
                      </b-table-column>

                      <!-- Cr -->
                      <b-table-column
                        field="kpiFact_cr"
                        :visible="columnsVisible['kpiFact_cr'].display"
                        :label="columnsVisible['kpiFact_cr'].title"
                        centered
                      >
                        <template v-slot:header="{ column }">
                          <span
                            v-b-tooltip.hover.v-info
                            title="Cr"
                            variant="outline-info"
                          >
                            {{ column.label }}
                          </span>
                        </template>
                        <template v-slot="props">
                          {{ columnValueFormat(props.row.kpiFact_cr) }}
                        </template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="kpi_cr"
                        :visible="columnsVisible['kpi_cr'].display"
                        :label="columnsVisible['kpi_cr'].title"
                        centered
                      >
                        {{ columnValueFormat(props.row.kpi_cr) }}
                      </b-table-column>

                      <!-- Cost (CRM) -->
                      <b-table-column
                        field="kpiFact_cost"
                        :visible="columnsVisible['kpiFact_cost'].display"
                        :label="columnsVisible['kpiFact_cost'].title"
                        centered
                      >
                        <template v-slot:header="{ column }">
                          <span
                            v-b-tooltip.hover.v-info
                            title="CPO"
                            variant="outline-info"
                          >
                            {{ column.label }}
                          </span>
                        </template>
                        <template v-slot="props">
                          {{ columnValueFormat(props.row.kpiFact_cost) }}
                        </template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="kpi_cost"
                        :visible="columnsVisible['kpi_cost'].display"
                        :label="columnsVisible['kpi_cost'].title"
                        centered
                      >
                        {{ columnValueFormat(props.row.kpi_cost) }}
                      </b-table-column>

                      <!-- Ctr -->
                      <b-table-column
                        field="kpiFact_ctr"
                        :visible="columnsVisible['kpiFact_ctr'].display"
                        :label="columnsVisible['kpiFact_ctr'].title"
                        centered
                      >
                        <template v-slot:header="{ column }">
                          <span
                            v-b-tooltip.hover.v-info
                            title="Ctr"
                            variant="outline-info"
                          >
                            {{ column.label }}
                          </span>
                        </template>
                        <template v-slot="props">
                          {{ columnValueFormat(props.row.kpiFact_ctr) }}
                        </template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="kpi_ctr"
                        :visible="columnsVisible['kpi_ctr'].display"
                        :label="columnsVisible['kpi_ctr'].title"
                        centered
                      >
                        {{ columnValueFormat(props.row.kpi_ctr) }}
                      </b-table-column>

                      <!-- Roi -->
                      <b-table-column
                        field="kpiFact_roi"
                        :visible="columnsVisible['kpiFact_roi'].display"
                        :label="columnsVisible['kpiFact_roi'].title"
                        centered
                      >
                        <template v-slot:header="{ column }">
                          <span
                            v-b-tooltip.hover.v-info
                            title="Roi"
                            variant="outline-info"
                          >
                            {{ column.label }}
                          </span>
                        </template>
                        <template v-slot="props">
                          {{ columnValueFormat(props.row.kpiFact_roi) }}
                        </template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="kpi_roi"
                        :visible="columnsVisible['kpi_roi'].display"
                        :label="columnsVisible['kpi_roi'].title"
                        centered
                      >
                        {{ columnValueFormat(props.row.kpi_roi) }}
                      </b-table-column>

                      <!-- discrepancy -->
                      <b-table-column
                        v-slot="props"
                        field="discrepancy"
                        :visible="columnsVisible['discrepancy'].display"
                        :label="columnsVisible['discrepancy'].title"
                        centered
                        sortable
                        :custom-sort="(a,b,isAsc) => customSort(a,b, isAsc, 'discrepancy')"
                      >
                        <b-badge :variant="checkEfColor(columnValueFormat(props.row.discrepancy))">
                          {{ columnValueFormat(props.row.discrepancy) }}
                        </b-badge>
                      </b-table-column>

                      <!-- Clicks -->
                      <b-table-column
                        field="clicks_was"
                        :visible="columnsVisible['clicks_was'].display"
                        :label="columnsVisible['clicks_was'].title"
                        centered
                      >
                        <template v-slot:header="{ column }">
                          <span
                            v-b-tooltip.hover.v-info
                            title="Клики (было)"
                            variant="outline-info"
                          >
                            {{ column.label }}
                          </span>
                        </template>
                        <template v-slot="props">
                          {{ columnValueFormat(props.row.clicks_was) }}
                        </template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="clicks"
                        :visible="columnsVisible['clicks'].display"
                        :label="columnsVisible['clicks'].title"
                        centered
                      >
                        {{ columnValueFormat(props.row.clicks) }}
                      </b-table-column>

                      <!-- TrafficVolume -->
                      <b-table-column
                        field="avgTrafficVolume_was"
                        :visible="columnsVisible['avgTrafficVolume_was'].display"
                        :label="columnsVisible['avgTrafficVolume_was'].title"
                        centered
                      >
                        <template v-slot:header="{ column }">
                          <span
                            v-b-tooltip.hover.v-info
                            title="Клики (было)"
                            variant="outline-info"
                          >
                            {{ column.label }}
                          </span>
                        </template>
                        <template v-slot="props">
                          {{ columnValueFormat(props.row.avgTrafficVolume_was) }}
                        </template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="avgTrafficVolume"
                        :visible="columnsVisible['avgTrafficVolume'].display"
                        :label="columnsVisible['avgTrafficVolume'].title"
                        centered
                      >
                        {{ columnValueFormat(props.row.avgTrafficVolume) }}
                      </b-table-column>

                      <b-table-column
                        v-slot="props"
                        field="action"
                        :visible="columnsVisible['action'].display"
                        :label="columnsVisible['action'].title"
                        centered
                      >
                        <b-form-checkbox
                          v-model="props.row.action"
                          style="width: 15px;margin: auto;"
                          class="custom-control-primary"
                          @change="check($event, props.row.id)"
                        />
                      </b-table-column>

                      <template
                        slot="detail"
                        slot-scope="props"
                      >

                        <tr
                          v-for="item in props.row.items"
                          :key="item.id"
                        >
                          <td v-if="showDetailIcon" />
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;{{ item.criterion }}
                            <b-button
                              v-if="item.type === 1"
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              v-b-tooltip.hover
                              variant="flat-success"
                              class="btn-icon rounded-circle sm"
                              title="К данному ключу применялась корректировка"
                            >
                              <feather-icon
                                variant="success"
                                icon="InfoIcon"
                              />
                            </b-button>
                          </td>

                          <!-- cr -->
                          <td
                            v-show="columnsVisible['kpiFact_cr'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpiFact_cr) }}
                          </td>
                          <td
                            v-show="columnsVisible['kpi_cr'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpi_cr) }}
                          </td>

                          <!-- cost -->
                          <td
                            v-show="columnsVisible['kpiFact_cost'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpiFact_cost) }}
                          </td>
                          <td
                            v-show="columnsVisible['kpi_cost'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpi_cost) }}
                          </td>

                          <!-- ctr -->
                          <td
                            v-show="columnsVisible['kpiFact_ctr'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpiFact_ctr) }}
                          </td>
                          <td
                            v-show="columnsVisible['kpi_ctr'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpi_ctr) }}
                          </td>

                          <!-- roi -->
                          <td
                            v-show="columnsVisible['kpiFact_roi'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpiFact_roi) }}
                          </td>
                          <td
                            v-show="columnsVisible['kpi_roi'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.kpi_roi) }}
                          </td>

                          <!-- discrepancy -->
                          <td
                            v-show="columnsVisible['discrepancy'].display"
                            class="has-text-centered"
                          >
                            <b-badge :variant="checkEfColor(columnValueFormat(item.discrepancy))">
                              {{ columnValueFormat(item.discrepancy) }}
                            </b-badge>
                          </td>

                          <!-- clicks -->
                          <td
                            v-show="columnsVisible['clicks_was'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.clicks_was) }}
                          </td>
                          <td
                            v-show="columnsVisible['clicks'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.clicks) }}
                          </td>

                          <!-- avgTrafficVolume -->
                          <td
                            v-show="columnsVisible['avgTrafficVolume_was'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.avgTrafficVolume_was) }}
                          </td>
                          <td
                            v-show="columnsVisible['avgTrafficVolume'].display"
                            class="has-text-centered"
                          >
                            {{ columnValueFormat(item.avgTrafficVolume) }}
                          </td>

                          <td
                            v-show="columnsVisible['action'].display"
                            class="has-text-centered"
                          >
                            <b-form-checkbox
                              v-model="item.action"
                              style="width: 15px;margin: auto;"
                              class="custom-control-primary"
                            />
                          </td>
                        </tr>
                      </template>
                    </b-table>
                  </b-card-body>
                </b-overlay>
              </b-card>
            </div>
          </b-tab>
        </b-tabs>
        <div
          v-if="settingResult == null"
          class="misc-inner p-2 p-sm-10 mt-3"
        >
          <div class="w-100 text-center">
            <h3 class="mb-1">
              У вас не заданы настройки рекомендаций, создайте в разделе "Настройки рекомендаций"
            </h3>
          </div>
        </div>

      </b-col>
    </b-row>
  </section>
</template>
<script>

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BCardTitle,
  BCol,
  BBadge,
  BTooltip,
  BRow,
  BImg,
  BTabs,
  BTab,
  BForm,
  BTableLite,
  BFormSelect,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BFormGroup,
  BFormCheckbox,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import KeysRecommendationDashboard from '@/views/recommendations/KeysRecommendationDashboard.vue'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    KeysRecommendationDashboard,
    BCard,
    BButton,
    BFormCheckbox,
    vSelect,
    BForm,
    BImg,
    BTooltip,
    BTabs,
    BTab,
    BFormSelect,
    BCardHeader,
    BTableLite,
    BCardBody,
    BOverlay,
    BCardTitle,
    BRow,
    BBadge,
    BCol,
    BFormCheckboxGroup,
    BFormGroup,
    BFormRadioGroup,
    flatPickr,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
  },
  data() {
    return {
      filterData: {
        value: 1,
        text: 'Показать все данные',
      },
      acceptLoading: false,
      optionsFilter: [
        {
          value: 2,
          text: 'Ключи с корректировкой',
        },
        {
          value: 1,
          text: 'Показать все данные',
        },
      ],
      checkedRows: [],
      isRecommendationShow: false,
      columnsVisible: {
        kpiFact_cr: { title: 'KPI (целевой Cr)', display: false },
        kpi_cr: { title: 'KPI (Cr)', display: false },
        kpiFact_cost: { title: 'KPI было (CPO)', display: false },
        kpi_cost: { title: 'KPI стало (CPO)', display: false },
        kpiFact_ctr: { title: 'KPI (целевой Ctr)', display: false },
        kpi_ctr: { title: 'KPI (Ctr)', display: false },
        kpiFact_roi: { title: 'KPI (целевой Roi)', display: false },
        kpi_roi: { title: 'KPI (Roi)', display: false },
        campaign: { title: 'Кампания', display: true },
        clicks_was: { title: 'Клики (было)', display: true },
        clicks: { title: 'Клики', display: true },
        avgTrafficVolume_was: { title: 'Средний объем трафика (было)', display: true },
        avgTrafficVolume: { title: 'Средний объем трафика (стало)', display: true },
        discrepancy: { title: 'Расхождение (%)', display: true },
        action: { title: 'Действия', display: true },
      },
      perPage: {
        value: 30,
        text: '30 на странице',
      },
      perEffectPage: {
        value: 30,
        text: '30 на странице',
      },
      data: [],
      effectData: [],
      dataInRange: [],
      dataInNotSeventyRange: [],
      dataInNotLessSeventyRange: [],
      isLoading: false,
      isEffectLoading: false,
      showDetailIcon: true,
      required,
      weekday: 1,
      settingResult: null,
      checkResult: null,
      checkEfficiencyResult: null,
      checkLoading: false,
      visibleProps: [],
      analyticProperties: [
        { title: 'Показы', value: 'shows' },
        { title: 'Клики', value: 'clicks' },
        { title: 'Расходы', value: 'consumption' },
        { title: 'Ctr', value: 'ctr' },
        { title: 'Средняя стоимость клика', value: 'avgCpc' },
        { title: 'Средний объем трафика', value: 'avgTrafficVolume' },
        { title: 'Выручка (аналитика)', value: 'analyticRevenue' },
        { title: 'Маржинальная прибыль (аналитика)', value: 'analyticMarginProfit' },
        { title: 'Кол-во заказов (аналитика)', value: 'analyticGoals' },
        { title: 'Стоимость заказов (аналитика)', value: 'analyticCost' },
        { title: 'Cr (аналитика)', value: 'analyticCr' },
        { title: 'Roi (аналитика)', value: 'analyticRoi' },
        { title: 'Выручка', value: 'profit' },
        { title: 'Маржинальная прибыль', value: 'marginProfit' },
        { title: 'Кол-во заказов', value: 'numberOrders' },
        { title: 'Стоимость заказов', value: 'cost' },
        { title: 'Cr', value: 'cr' },
        { title: 'Roi', value: 'roi' },
      ],
      dataTest: {},
      allKeys: [],
      saveStatisticData: [],
      actuallyRuleIds: null,
      kpi: null,
    }
  },
  computed: {
    settingResultComp() {
      return this.$store.state.statistic.settingResult
    },
    recommendationStats() {
      return this.$store.state.statistic.recommendationStats
    },
    efficiencyStats() {
      return this.$store.state.statistic.efficiencyStats
    },
    kpiData() {
      return this.$store.state.statistic.kpiData
    },
    activeProjectId() {
      return this.$store.state.project.activeProjectId
    },
    activeProject() {
      return this.$store.state.project.projects.find(c => c.id === this.activeProjectId)
    },
    countChecked() {
      let count = 0
      this.effectData.forEach(item => {
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          if (subItem.action) {
            count += 1
          }
        })
      })

      return count
    },
  },
  watch: {
    filterData() {
      if (this.filterData.value === 1) {
        this.effectData = JSON.parse(JSON.stringify(this.saveStatisticData))
      }
      if (this.filterData.value === 2) {
        const data = JSON.parse(JSON.stringify(this.saveStatisticData))

        const parseData = []

        data.forEach(item => {
          let check = false
          const parsedItem = JSON.parse(JSON.stringify(item))

          parsedItem.items = []

          item.items.forEach(subItem => {
            if (subItem.type === 1) {
              check = true
              parsedItem.items.push(subItem)
            }
          })

          if (check) {
            parseData.push(parsedItem)
          }
        })

        this.effectData = parseData
      }
    },
    activeProjectId(oldState, newState) {
      this.isRecommendationShow = true
      console.log(oldState, 'oldState')
      console.log(newState, 'newState')
      if (newState != null && oldState !== newState) {
        this.$store.commit('statistic/CLEAR_RECOMMENDATION_DATA')
        this.$store.commit('statistic/CLEAR_EFFICIENCY_DATA')
        this.$store.commit('statistic/CLEAR_KPI')
        this.$store.commit('statistic/CLEAR_SETTING_REC')
        this.settingResult = null
        this.data = []
        this.effectData = []
        this.visibleProps = []
        this.checkEfficiencyResult = null
        this.checkResult = null
      }

      if (this.recommendationStats == null) {
        this.showSettings()
      } else {
        this.data = this.recommendationStats.data
        this.dataInNotSeventyRange = this.recommendationStats.dataInNotSeventyRange
        this.dataInNotLessSeventyRange = this.recommendationStats.dataInNotLessSeventyRange
        this.dataInRange = this.recommendationStats.dataInRange
        this.effectData = this.efficiencyStats
        this.settingResult = this.settingResultComp
        this.visibleProps.push(this.kpiData.fact)
        this.visibleProps.push(this.kpiData.complain)
        this.columnsVisible[this.kpiData.fact].display = true
        this.columnsVisible[this.kpiData.complain].display = true
        this.checkEfficiencyResult = { type: 1 }
        this.checkResult = { type: 1 }
        this.isRecommendationShow = false
      }
    },
  },
  mounted() {
    this.isRecommendationShow = true
    if (this.recommendationStats == null) {
      console.log(this.recommendationStats, 'this.recommendationStats')
      this.showSettings()
    } else {
      this.settingResult = this.settingResultComp
      this.data = this.recommendationStats.data
      this.dataInNotSeventyRange = this.recommendationStats.dataInNotSeventyRange
      this.dataInNotLessSeventyRange = this.recommendationStats.dataInNotLessSeventyRange
      this.dataInRange = this.recommendationStats.dataInRange
      this.effectData = this.efficiencyStats
      this.visibleProps.push(this.kpiData.fact)
      this.visibleProps.push(this.kpiData.complain)
      this.columnsVisible[this.kpiData.fact].display = true
      this.columnsVisible[this.kpiData.complain].display = true
      this.checkResult = { type: 1 }
      this.checkEfficiencyResult = { type: 1 }
      this.isRecommendationShow = false
    }
  },
  created() {
  },
  methods: {
    customSort(a, b, isAsc, val) {
      if (!isAsc) {
        a.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        b.items.sort((ai, bi) => Number(bi[val]) - Number(ai[val]))
        return b[val] - a[val]
      }
      a.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      b.items.sort((ai, bi) => Number(ai[val]) - Number(bi[val]))
      return a[val] - b[val]
    },
    loadActuallyRule() {
      if (this.activeProjectId != null) {
        this.$http.get(`adrules/automatic-actually?projectId=${this.activeProjectId}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            // TO-DO: NEED refactoring
            const responseData = response.data
            this.actuallyRuleIds = responseData
          })
      }
    },
    correctKeys() {
      this.cancelRules()
    },
    cancelRules() {
      const keys = []

      this.effectData.forEach(item => {
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          if (subItem.action) {
            const parseKey = {
              campaign: item.campaign,
              keyword: subItem.criterion,
            }
            keys.push(parseKey)
          }
        })
      })

      this.$http.post('yandex-direct/criterion-ids', keys)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // TO-DO: NEED refactoring
          const responseData = response.data

          this.$http.post('recommendation/execute', responseData)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              // TO-DO: NEED refactoring

            })
        })
    },
    cancelKeys() {
      this.effectData.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action = false
        item.items.forEach(subItem => {
          // eslint-disable-next-line no-param-reassign
          subItem.action = false
        })
      })
    },
    check(e, id) {
      const item = this.effectData.find(c => c.id === id)

      item.items.forEach(subItem => {
        // eslint-disable-next-line no-param-reassign
        subItem.action = e
      })
    },
    checkEfColor(val) {
      if (val === '-') {
        return 'secondary'
      }
      return parseFloat(val) > 50 ? 'danger' : 'success'
    },
    calculateCount(data) {
      let count = 0
      if (data !== null) {
        data.forEach(item => {
          count += item.items.length
        })
      }
      return count
    },
    columnValueFormat(val) {
      if (val === null || val === '-' || val === undefined || isNaN(val)) {
        return '-'
      }

      if (val === 0) {
        return 0
      }

      if (Math.round(+val) !== +val) {
        return (+val.toFixed(2)).toLocaleString()
      }

      return (+val).toLocaleString()
    },
    calculateDependedFields(stat) {
      // eslint-disable-next-line radix

      stat.ctr = stat.shows !== 0
        ? ((parseInt(stat.clicks) / parseInt(stat.shows)) * 100) : '-'

      stat.avgCpc = stat.clicks !== 0
        ? ((parseFloat(stat.consumption) / parseInt(stat.clicks))) : '-'

      // calculate CRM values
      stat.roi = stat.consumption !== 0
        ? ((parseFloat(stat.profit) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.cr = stat.clicks !== 0
        ? ((parseFloat(stat.numberOrders) / parseInt(stat.clicks)) * 100) : '-'

      stat.cost = stat.numberOrders !== 0
        ? ((parseFloat(stat.consumption) / parseFloat(stat.numberOrders))) : '-'

      // calculate Analytic values
      stat.analyticRoi = stat.consumption !== 0
        ? ((parseFloat(stat.analyticRevenue) - parseFloat(stat.consumption)) / parseFloat(stat.consumption)) * 100 : '-'

      stat.analyticCr = stat.clicks !== 0
        ? ((parseFloat(stat.analyticGoals) / parseInt(stat.clicks)) * 100) : '-'

      stat.analyticCost = stat.numberOrders !== 0
        ? ((parseFloat(stat.consumption) / parseFloat(stat.analyticGoals))) : '-'
    },
    calculateTableData(dataForParse, type) {
      const dataTemp = []
      // table parsed
      let increment = 0
      Object.keys(dataForParse)
        .forEach(key => {
          const statItem = dataForParse[key]

          const stat = {
            id: 0,
            campaign: key,
            shows: 0,
            clicks: 0,
            consumption: 0,
            avgCpc: 0,
            ctr: 0,
            marginProfit: 0,
            profit: 0,
            cost: 0,
            cr: 0,
            numberOrders: 0,
            analyticRevenue: 0,
            analyticGoals: 0,
            avgTrafficVolume: 0,
            analyticMarginProfit: 0,
            items: [],
          }

          function calculateAverage(array) {
            let total = 0
            let count = 0

            array.forEach((item, index) => {
              total += item
              count++
            })

            return total / count
          }

          const subAvgTrafficVolumes = []

          statItem.forEach(item => {
            stat.shows += parseInt(item.shows)
            stat.clicks += parseInt(item.clicks)
            stat.consumption += parseFloat(item.consumption)
            stat.numberOrders += parseInt(item.numberOrders)
            subAvgTrafficVolumes.push(parseInt(item.avgTrafficVolume))
            stat.profit += parseFloat(item.profit)
            stat.analyticRevenue += parseFloat(item.analyticRevenue)
            stat.analyticGoals += parseFloat(item.analyticGoals)
            stat.analyticMarginProfit = stat.analyticRevenue - stat.consumption
            stat.marginProfit = stat.profit - stat.consumption

            const subStat = {
              // eslint-disable-next-line no-plusplus
              id: `stat${increment++}`,
              criterion: item.criterion,
              criterionId: item.criterionId,
              shows: item.shows,
              clicks: item.clicks,
              consumption: item.consumption,
              profit: item.profit,
              marginProfit: item.marginProfit,
              numberOrders: item.numberOrders,
              analyticRevenue: item.analyticRevenue,
              analyticGoals: item.analyticGoals,
              analyticMarginProfit: item.analyticMarginProfit,
              avgTrafficVolume: item.avgTrafficVolume,
              // calculated crm values
              ctr: 0,
              avgCpc: 0,
              cr: 0,
              roi: 0,
              cost: 0,
              // calculated analytic values
              analyticRoi: 0,
              analyticCr: 0,
              analyticCost: 0,
            }

            this.calculateDependedFields(subStat)
            stat.avgTrafficVolume = calculateAverage(subAvgTrafficVolumes)

            this.allKeys.push(subStat)

            const recommendationSubStat = {
              id: subStat.id,
              criterion: item.criterion,
              criterionId: item.criterionId,
              avgTrafficVolume: item.avgTrafficVolume,
            }

            if (this.settingResult != null) {
              this.settingResult.indicators.split(',')
                .forEach(subItem => {
                  const parseValue = this.analyticProperties
                    .find(c => c.title === subItem).value

                  recommendationSubStat[parseValue] = subStat[parseValue]
                })

              if (type === 'efficiency') {
                recommendationSubStat.clicks = item.clicks
                recommendationSubStat.avgTrafficVolume = item.avgTrafficVolume
              }

              stat.items.push(recommendationSubStat)
            }
          })

          // set the rule how to calculate analytic and crm
          this.calculateDependedFields(stat)

          // eslint-disable-next-line no-plusplus
          stat.id = `stat${increment++}`

          const recommendationStat = {
            id: stat.id,
            campaign: stat.campaign,
            items: stat.items,
          }

          if (this.settingResult != null) {
            this.settingResult.indicators.split(',').forEach(item => {
              const parseValue = this.analyticProperties
                .find(c => c.title === item).value

              recommendationStat[parseValue] = stat[parseValue]
            })
          }

          if (type === 'efficiency') {
            recommendationStat.clicks = stat.clicks
            recommendationStat.avgTrafficVolume = stat.avgTrafficVolume
          }

          dataTemp.push(recommendationStat)
        })

      return dataTemp
    },
    loadEfficiencySummary() {
      if (this.activeProjectId != null && this.settingResult != null) {
        this.loadActuallyRule()
        this.$http.get(`recommendation/efficiency?projectId=${this.activeProjectId}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            // TO-DO: NEED refactoring
            const responseData = response.data

            this.checkEfficiencyResult = {
              description: responseData.description,
              type: responseData.type,
            }

            if (this.checkEfficiencyResult.type === 1) {
              this.checkEfficiencyResult.summaryStats = this.calculateTableData(
                responseData.summaryStats, 'efficiency',
              )
              this.checkEfficiencyResult.pastedStats = this.calculateTableData(
                responseData.pastedStats, 'efficiency',
              )
              this.calculateEfficiencyTable()
            }

            this.isRecommendationShow = false
            this.checkLoading = false
          })
      }
    },
    calculateEfficiencyTable() {
      if (this.checkEfficiencyResult.summaryStats != null) {
        const tempStats = []

        if (this.activeProject.keyIndicators != null) {
          const parsedKeyIndicators = this.activeProject.keyIndicators.split(',')
          const parsedKeys = []
          parsedKeyIndicators.forEach(item => {
            const indicator = item.replace(/% ?/g, '')
              .replace(/руб./g, '')
              .split('=')
              .map(i => i.trim())

            parsedKeys.push({
              key: indicator[0],
              value: indicator[1],
            })
          })

          const parseItemTitle = this.analyticProperties
            .find(c => c.title === parsedKeys[0].key).value

          console.log(this.actuallyRuleIds, 'this.actuallyRuleIds')

          this.checkEfficiencyResult.summaryStats.forEach(item => {
            const fixItem = {
              id: item.id,
              campaign: item.campaign,
              clicks: item.clicks,
              action: false,
              avgTrafficVolume: item.avgTrafficVolume,
              items: [],
            }

            const pastedItem = this.checkEfficiencyResult
              .pastedStats
              .find(c => c.campaign === item.campaign)

            if (pastedItem != null) {
              fixItem.clicks_was = pastedItem.clicks
              fixItem.avgTrafficVolume_was = pastedItem.avgTrafficVolume

              const complainFactKey = `kpiFact_${parseItemTitle}`
              fixItem[complainFactKey] = item[parseItemTitle]
              const complainKey = `kpi_${parseItemTitle}`
              fixItem[complainKey] = pastedItem[parseItemTitle]
              // eslint-disable-next-line radix
              fixItem.discrepancy = parseFloat(fixItem[complainFactKey]) !== 0
                ? ((parseFloat(fixItem[complainFactKey]) - parseFloat(fixItem[complainKey])) / parseFloat(fixItem[complainFactKey])) * 100 : '-'

              item.items.forEach(subItem => {
              // eslint-disable-next-line no-nested-ternary
                const isAdjsut = this.actuallyRuleIds != null ? (this.actuallyRuleIds.includes(subItem.criterionId)
                  ? 1 : 0) : 0

                const subFixedItem = {
                  id: subItem.id,
                  clicks: subItem.clicks,
                  action: false,
                  type: isAdjsut,
                  criterion: subItem.criterion,
                  criterionId: subItem.criterionId,
                  avgTrafficVolume: subItem.avgTrafficVolume,
                  clicks_was: 0,
                  avgTrafficVolume_was: 0,
                }

                const pastedFixedItem = pastedItem.items.find(c => c.criterion === subItem.criterion)

                if (pastedFixedItem != null) {
                  subFixedItem.clicks_was = pastedFixedItem.clicks
                  subFixedItem.avgTrafficVolume_was = pastedFixedItem.avgTrafficVolume

                  const complainFactKey = `kpiFact_${parseItemTitle}`
                  subFixedItem[complainFactKey] = subItem[parseItemTitle]
                  const complainKey = `kpi_${parseItemTitle}`
                  subFixedItem[complainKey] = pastedFixedItem[parseItemTitle]
                  // eslint-disable-next-line radix
                  subFixedItem.discrepancy = parseFloat(subFixedItem[complainFactKey]) !== 0
                    ? ((parseFloat(subFixedItem[complainFactKey]) - parseFloat(subFixedItem[complainKey])) / parseFloat(subFixedItem[complainFactKey])) * 100 : '-'

                  fixItem.items.push(subFixedItem)
                }
              })

              tempStats.push(fixItem)
              this.saveStatisticData.push(fixItem)
            }
          })

          this.effectData = tempStats
          this.$store.commit('statistic/LOAD_EFFICIENCY_DATA', this.effectData)
        }
      }
    },
    loadRecommendationSummary() {
      if (this.activeProjectId != null && this.settingResult != null) {
        this.$http.get(`recommendation/check?projectId=${this.activeProjectId}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            // TO-DO: NEED refactoring
            const responseData = response.data
            this.checkResult = {
              description: responseData.description,
              type: responseData.type,
            }

            if (this.checkResult.type === 1) {
              this.checkResult.summaryStats = this.calculateTableData(responseData.summaryStats)
              this.calculateSummaryTable()
            }

            this.checkLoading = false
          })
      }
    },
    extractData(statsOne, parseSettingIndicators, type) {
      const dataInNotSeventyRange = []

      function calculateAverage(array) {
        let total = 0
        let count = 0

        array.forEach((item, index) => {
          total += item
          count++
        })

        return total / count
      }

      statsOne.forEach(item => {
        const cloneItem = item
        let subItems = []

        // TODO: refactoring
        if (type === 1) {
          subItems = item.items.filter(c => c.discrepancy > -70 && c.discrepancy <= 0)
        }
        if (type === 2) {
          subItems = item.items.filter(c => c.discrepancy < -70)
        }
        if (type === 3) {
          subItems = item.items.filter(c => c.discrepancy > 0)
        }

        cloneItem.items = subItems

        const subtests = []

        cloneItem.items.forEach(subItem => {
          const test = this.allKeys.find(c => c.id === subItem.id)
          subtests.push(test)
        })

        cloneItem.shows = 0
        cloneItem.clicks = 0
        cloneItem.consumption = 0
        cloneItem.avgCpc = 0
        cloneItem.ctr = 0
        cloneItem.marginProfit = 0
        cloneItem.profit = 0
        cloneItem.cost = 0
        cloneItem.cr = 0
        cloneItem.numberOrders = 0
        cloneItem.analyticRevenue = 0
        cloneItem.analyticGoals = 0
        cloneItem.avgTrafficVolume = 0
        cloneItem.analyticMarginProfit = 0
        cloneItem.action = false

        const averageTraffics = []

        subtests.forEach(tt => {
          cloneItem.shows += parseInt(tt.shows)
          cloneItem.clicks += parseInt(tt.clicks)
          cloneItem.consumption += parseFloat(tt.consumption)
          cloneItem.numberOrders += parseInt(tt.numberOrders)
          averageTraffics.push(parseInt(tt.avgTrafficVolume))
          cloneItem.profit += parseFloat(tt.profit)
          cloneItem.analyticRevenue += parseFloat(tt.analyticRevenue)
          cloneItem.analyticGoals += parseFloat(tt.analyticGoals)
          cloneItem.analyticMarginProfit = cloneItem.analyticRevenue - cloneItem.consumption
          cloneItem.marginProfit = cloneItem.profit - cloneItem.consumption
          this.calculateDependedFields(cloneItem)
        })

        cloneItem.avgTrafficVolume = calculateAverage(averageTraffics)

        for (const psIndicator of parseSettingIndicators) {
          const complainFactKey = `kpiFact_${psIndicator.key}`
          cloneItem[complainFactKey] = psIndicator.value
          const complainKey = `kpi_${psIndicator.key}`
          cloneItem[complainKey] = cloneItem[psIndicator.key]
          // eslint-disable-next-line radix
          cloneItem.discrepancy = parseFloat(cloneItem[complainFactKey]) !== 0
            ? ((parseFloat(cloneItem[complainFactKey]) - parseFloat(cloneItem[complainKey]))
              / parseFloat(cloneItem[complainFactKey])) * 100 : '-'
        }

        if (subItems.length !== 0 && cloneItem.discrepancy !== '-') {
          dataInNotSeventyRange.push(cloneItem)
        }
      })

      return dataInNotSeventyRange
    },
    calculateSummaryTable() {
      const tempStats = []

      const parsedKeyIndicators = this.activeProject.keyIndicators.split(',')
      const parsedKeys = []
      parsedKeyIndicators.forEach(item => {
        const indicator = item.replace(/% ?/g, '')
          .replace(/руб./g, '')
          .split('=')
          .map(i => i.trim())

        parsedKeys.push({
          key: indicator[0],
          value: indicator[1],
        })
      })

      const parseSettingIndicators = []
      this.settingResult.indicators.split(',').forEach(item => {
        const parseItem = this.analyticProperties
          .find(c => c.title === item).value

        const { value } = parsedKeys.find(c => c.key === item)

        parseSettingIndicators.push({
          title: item,
          key: parseItem,
          value,
        })
      })

      // eslint-disable-next-line no-restricted-syntax
      for (const psIndicator of parseSettingIndicators) {
        const complainFactKey = `kpiFact_${psIndicator.key}`
        const complainKey = `kpi_${psIndicator.key}`

        this.kpi = {
          fact: complainFactKey,
          complain: complainKey,
        }

        this.$store.commit('statistic/LOAD_KPI', this.kpi)
        this.columnsVisible[complainFactKey].display = true
        this.columnsVisible[complainKey].display = true
        this.visibleProps.push(complainFactKey)
        this.visibleProps.push(complainKey)
      }

      this.checkResult.summaryStats.forEach(periodItem => {
        const obj = {
          id: periodItem.id,
          campaign: periodItem.campaign,
          items: [],
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const psIndicator of parseSettingIndicators) {
          const complainFactKey = `kpiFact_${psIndicator.key}`
          obj[complainFactKey] = psIndicator.value
          const complainKey = `kpi_${psIndicator.key}`
          obj[complainKey] = periodItem[psIndicator.key]
          // eslint-disable-next-line radix
          obj.discrepancy = parseFloat(obj[complainFactKey]) !== 0
            ? ((parseFloat(obj[complainFactKey]) - parseFloat(obj[complainKey])) / parseFloat(obj[complainFactKey])) * 100 : '-'
        }

        periodItem.items.forEach(subPeriodItem => {
          const subObj = {
            id: subPeriodItem.id,
            criterion: subPeriodItem.criterion,
            avgTrafficVolume: subPeriodItem.avgTrafficVolume,
            action: false,
          }

          let checkValues = ''
          // eslint-disable-next-line no-restricted-syntax
          for (const psIndicator of parseSettingIndicators) {
            const complainFactKey = `kpiFact_${psIndicator.key}`
            subObj[complainFactKey] = psIndicator.value
            const complainKey = `kpi_${psIndicator.key}`
            subObj[complainKey] = subPeriodItem[psIndicator.key]
            checkValues = subPeriodItem[psIndicator.key]
            // eslint-disable-next-line radix
            subObj.discrepancy = parseFloat(subObj[complainFactKey]) !== 0
              ? ((parseFloat(subObj[complainFactKey]) - parseFloat(subObj[complainKey]))
                / parseFloat(subObj[complainFactKey])) * 100 : '-'
          }

          if (checkValues !== '-' && subObj.discrepancy !== '-') {
            obj.items.push(subObj)
          }
        })

        tempStats.push(obj)
      })

      let dataInNotSeventyRange = []
      let dataInNotLessSeventyRange = []
      let dataInRange = []

      const statsOne = JSON.parse(JSON.stringify(tempStats))
      dataInNotSeventyRange = this.extractData(statsOne, parseSettingIndicators, 1)

      const statsTwo = JSON.parse(JSON.stringify(tempStats))
      dataInNotLessSeventyRange = this.extractData(statsTwo, parseSettingIndicators, 2)

      const statsThree = JSON.parse(JSON.stringify(tempStats))
      dataInRange = this.extractData(statsThree, parseSettingIndicators, 3)

      // 3 dashboards
      this.dataInNotSeventyRange = dataInNotSeventyRange
      this.dataInNotLessSeventyRange = dataInNotLessSeventyRange
      this.dataInRange = dataInRange

      const dataCommit = {
        data: this.data,
        dataInNotSeventyRange: this.dataInNotSeventyRange,
        dataInNotLessSeventyRange: this.dataInNotLessSeventyRange,
        dataInRange: this.dataInRange,
      }

      this.$store.commit('statistic/LOAD_RECOMMENDATION_DATA', dataCommit)
    },
    showSettings() {
      if (this.activeProjectId != null) {
        this.$http.get(`recommendation/show?projectId=${this.activeProjectId}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            // TO-DO: NEED refactoring
            const responseData = response.data
            if (responseData != null && responseData !== '') {
              const obj = {
                indicators: responseData.indicators,
                period: responseData.period,
                projectId: responseData.projectId,
                weekday: responseData.weekday,
                dateRange: responseData.dateRange,
              }

              this.settingResult = obj
              this.$store.commit('statistic/LOAD_SETTING_REC', this.settingResult)
              this.loadRecommendationSummary()
              this.loadEfficiencySummary()
            }
          })
      }
    },
  },
}
</script>

<style scoped>

.no-content {
  margin:0 auto;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
